<template>
     <v-row>
        <v-col class="py-0">
            <v-card width="70vw" flat>
                <v-expand-transition>
                    <v-card flat v-show="expandStudents">
                        <v-data-table
                            :headers="headers"
                            :items="students"
                            :search="search"
                            fixed-header
                            height="50vh"
                            :loading="loading"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Search Student"
                                        single-line
                                        hide-details
                                        class="mb-4 pt-4"
                                        clearable
                                    ></v-text-field>
                                </v-toolbar>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    outlined
                                    color="primary"
                                    @click="show(item)"
                                    small
                                >
                                    Select
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-expand-transition>

                <v-expand-transition>
                    <v-card
                        flat
                        v-show="expandStudentAttendance"
                        width="75vw"
                    >
                        <v-row
                           
                            align="center"
                            justify="center"
                        >
                            <v-card width="75vw" flat>
                                <v-card-title
                                    class="primary white--text"
                                >
                                    {{ selectedStudent.name }}
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="white"
                                        outlined
                                        @click="showAttendanceReport"
                                    >
                                        Attendance Report
                                    </v-btn>
                                </v-card-title>

                                <v-card-text class="pb-0">
                                    <v-row  v-show="!deleteAttendance">
                                        <v-col cols="4"  v-show="!expand">
                                            <v-card flat>
                                                <v-card-text>
                                                    <div class="mb-4">{{ term }}</div>
                                                    <div class="mb-4">Student ID: {{ selectedStudent.id }}</div>
                                                    <div >Class: {{ selectedStudent.classId }}</div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="4" v-show="!expand">
                                            <v-card flat class="d-flex pl-3">
                                                <ChartDoughnut
                                                    v-if="loadedAttendance"
                                                    :chart-data="attendanceData"
                                                    :chart-options="chartOptions"
                                                ></ChartDoughnut>
                                                <div class="ml-3">
                                                    <v-card flat>
                                                        <v-card-title class="text-caption pb-0 pr-0">
                                                            Attendance
                                                        </v-card-title>
                                                        <v-card-text class="text-h4 pb-0">
                                                            {{ regularityPercentage}}
                                                        </v-card-text>
                                                        <v-card-subtitle class="py-0">
                                                            {{ regularity }}
                                                        </v-card-subtitle>
                                                        <v-card-subtitle class="pt-0">
                                                            {{ sessionsAbsent }}
                                                        </v-card-subtitle>
                                                    </v-card>
                                                </div>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="4" v-show="!expand">
                                            <v-card flat class="d-flex pl-3">
                                                <ChartDoughnut
                                                    v-if="loadedPunctuality"
                                                    :chart-data="lateData"
                                                    :chart-options="chartOptions"
                                                ></ChartDoughnut>
                                                <div class="ml-3">
                                                    <v-card flat>
                                                        <v-card-title class="text-caption pb-0">
                                                            Punctual
                                                        </v-card-title>
                                                        <v-card-text class="text-h4 pb-0">
                                                            {{ punctualityPercentage}}
                                                        </v-card-text>
                                                        <v-card-subtitle class="pt-0">
                                                            {{ punctuality }}
                                                        </v-card-subtitle>
                                                    
                                                    </v-card>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="pt-0">
                                            <v-card class="py-0" flat>
                                                <v-card-title class="pt-0 pr-0">
                                                    Attendance Details
                                                    <v-spacer></v-spacer>
                                                    <v-text-field
                                                        v-model="searchAttendance"
                                                        append-icon="mdi-magnify"
                                                        label="Search Date"
                                                        single-line
                                                        hide-details
                                                    ></v-text-field>

                                                    <v-tooltip top v-if="!expand">
                                                        <template v-slot:activator="{ on , attrs }">
                                                            <v-btn 
                                                                icon 
                                                                class="ml-4 mt-3"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="expandAttendanceDetails"
                                                            >
                                                                <v-icon>mdi-open-in-new</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Click to expand attendance details</span>
                                                    </v-tooltip>

                                                    <v-tooltip top v-else>
                                                        <template v-slot:activator="{ on , attrs }">
                                                            <v-btn 
                                                                icon 
                                                                class="ml-4 mt-3"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="showAttendanceCharts"
                                                            >
                                                                <v-icon>mdi-chart-donut</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Click to show charts</span>
                                                    </v-tooltip>
                                                </v-card-title>
                                                <v-data-table
                                                    v-model="selectedRecords"
                                                    :headers="headersStudentAttendance"
                                                    :items="attendanceRecords"
                                                    :search="searchAttendance"
                                                    :height="tableHeight"
                                                    fixed-header
                                                    :loading="loadingAttendance"
                                                    :show-select="showSelect"
                                                    :single-select="singleSelect"
                                                    dense
                                                >

                                                    <template v-slot:item="{ item }">
                                                        <tr>
                                                            <td v-if="showSelect">
                                                                <v-checkbox
                                                                    v-model="selectedRecords"
                                                                    :value="item"
                                                                    hide-details
                                                                ></v-checkbox>
                                                            </td>
                                                            <td>{{ item.date_scanned }}</td>
                                                            <td>
                                                                <v-icon
                                                                    v-if="item.am == 'mdi-check'" 
                                                                    color="green"
                                                                >
                                                                    {{ item.am }}
                                                                </v-icon>
                                                                <span
                                                                    v-else
                                                                >
                                                                    {{ item.am }}
                                                                </span>
                                                            </td>                                
                                                            <td>
                                                                <v-icon 
                                                                    v-if="item.am == 'mdi-check'" 
                                                                    color="green"
                                                                >
                                                                    {{ item.pm }}
                                                                </v-icon>
                                                                <span
                                                                    v-else
                                                                >
                                                                    {{ item.pm }}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span 
                                                                    v-if="item.late == 'Y'"
                                                                    class="red--text"
                                                                >
                                                                    {{ item.late }}
                                                                </span>
                                                                <span v-else>
                                                                    {{ item.late }}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span 
                                                                    v-if="item.late == 'Y'"
                                                                    class="red--text"
                                                                >
                                                                    {{ item.time_scanned_in }}
                                                                </span>
                                                                <span v-else>
                                                                    {{ item.time_scanned_in }}
                                                                </span>
                                                            </td>
                                                            <td>{{ item.time_scanned_out }}</td>
                                                            <!-- <td v-if="!showSelect">
                                                                <v-btn
                                                                    outlined
                                                                    color="primary"
                                                                    @click="editAttendance(item)"
                                                                    small
                                                                >
                                                                    <v-icon
                                                                        medium
                                                                        left
                                                                        small
                                                                    >
                                                                        mdi-account-edit
                                                                    </v-icon>
                                                                    Edit
                                                                </v-btn>
                                                            </td> -->
                                                        </tr>
                                                    </template> 
                                                </v-data-table>
                                            </v-card>    
                                        </v-col>
                                    </v-row>
                                
                                </v-card-text>

                                <v-overlay
                                    :value="overlayProgress"
                                    absolute 
                                >
                                    <v-progress-circular
                                        indeterminate
                                        size="64"
                                        
                                    ></v-progress-circular>                   
                                </v-overlay>

                                <v-overlay
                                    :value="overlayMenu"
                                >
                                    <v-card                        
                                        light
                                        max-width="380"
                                        class="pa-4"
                                    >
                                        <v-card-title>
                                            {{ selectedStudent.name }} Attendance
                                        </v-card-title>

                                        <v-card-text>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                >
                                                <v-date-picker
                                                        label="Select Date Range"
                                                        v-on:update-range="setDateRange"
                                                        v-model="dateRange"
                                                        range
                                                ></v-date-picker>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>                                    

                                        <v-card-actions>
                                            
                                            <v-btn
                                                color="primary"
                                                outlined
                                                @click="closeReportMenu"
                                                class="mr-2 flex-grow-1"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                dark
                                                @click="displayReport"
                                            >
                                                Display Report
                                            </v-btn>
                                        </v-card-actions>

                                        <v-overlay
                                            absolute
                                            :value="loading"                    
                                            color="grey lighten-5"
                                            opacity="0.9"
                                            class="pa-5"
                                        > 
                                            <v-progress-circular
                                                indeterminate 
                                                size="64"                            
                                                color="primary"                                
                                            ></v-progress-circular>                   
                                        </v-overlay>
                                    </v-card>

                                </v-overlay>  
                            </v-card>
                        </v-row>
                    </v-card>
                </v-expand-transition>
            </v-card>    
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format } from 'date-fns';
// import DatePickerRange from './DatePickerRange'
import ChartDoughnut from './ChartDoughnut.vue';
// import { format } from 'date-fns';
export default {
    components: {
        // DatePickerRange,
        ChartDoughnut
    },

    props: {
        showStudents: Boolean,
        showAttendanceReportMenu: Boolean,
        deleteAttendance: Boolean,
    },

    watch: {
        showStudents (val) {
            if(val) {
                this.expandStudentAttendance = false;
                setTimeout(() => {
                    this.expandStudents = true;
                }, 600)
            }
        },

        showAttendanceReportMenu (val) {
            if(val) this.overlayMenu = true;
        },

        deleteAttendance (val) {
            if(val) {
                this.showSelect = true;
                this.tableHeight = '43vh';
                return;
            }
                
            this.tableHeight = this.defaultTableHeight;
            this.showSelect = false;
        },

        selectedRecords (val) {
            this. setAttendanceRecordsSelected(val);
        },

        showSelect (val) {
            if(val){
                this.headersStudentAttendance = [
                    { text: 'Date', value: 'date_scanned'},
                    { text: 'AM', value: 'am'},
                    { text: 'PM', value: 'pm'},
                    { text: 'Late', value: 'late'},
                    { text: 'Time In', value: 'time_scanned_in'},
                    { text: 'Time Out', value: 'time_scanned_out'},
                ]
                return
            }

            this.headersStudentAttendance = [
                { text: 'Date', value: 'date_scanned'},
                { text: 'AM', value: 'am'},
                { text: 'PM', value: 'pm'},
                { text: 'Late', value: 'late'},
                { text: 'Time In', value: 'time_scanned_in'},
                { text: 'Time Out', value: 'time_scanned_out'},
                // { text: 'Actions', value: 'actions', sortable: false },
            ]
        }
    },

    created () {
        this.initialize();
    },

    data: () => ({
        dateRange: [],
        students: [],
         headers:[
            { text: 'ID', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Form Class', value: 'classId' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        search: '',
        searchAttendance: '',
        loading: false,
        loadingAttendance: false,
        expandStudents: true,
        expandStudentAttendance: false,
        overlayProgress: false,
        loadedAttendance: false,
        loadedPunctuality: false,
        headersStudentAttendance: [
            { text: 'Date', value: 'date_scanned'},
            { text: 'AM', value: 'am'},
            { text: 'PM', value: 'pm'},
            { text: 'Late', value: 'late'},
            { text: 'Time In', value: 'time_scanned_in'},
            { text: 'Time Out', value: 'time_scanned_out'},
            // { text: 'Actions', value: 'actions', sortable: false },
        ],
        attendanceRecords: [],
        selectedStudent: {
            classId: null,
            id: null,
            name: null,
        },
        lateData: null,
        regularityPercentage: 0,
        regularity: null,
        sessionsAbsent: null,
        punctualityPercentage: 0,
        punctuality: null,
        chartOptions: {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                    position: 'bottom'
                },
                
            },
            animation: {
                animateScale: true
            },  
            cutout: '80%',
            text: '39%',
        },
        overlayMenu: false,
        dateRangeReport: [],
        showSelect: false,
        selectedRecords: [],
        tableHeight: '20vh',
        defaultTableHeight: '20vh',
        singleSelect: false,
        expand: false,
        term: null,
    }),

    computed: {
        ...mapGetters({
            attendanceSelectedStudent : 'admin/getAttendanceSelectedStudent',
        })
    },

    methods: {
        ...mapActions({
            getStudents: 'admin/getStudents',
            getAttendanceRecords: 'admin/getAttendanceRecords',
        }),

        ...mapMutations({
            setAttendanceSelectedStudent: 'admin/setAttendanceSelectedStudent',
            setAttendanceRecordsSelected: 'admin/setAttendanceRecordsSelected',
        }),

        async initialize () {
            this.loading = true;
            const { data } = await this.getStudents();
            this.mapStudents(data);
            this.loading = false;
            if(this.attendanceSelectedStudent?.id){
                //student was selected an report closed
                this.show(this.attendanceSelectedStudent)
            }
        },

        mapStudents (data) {
            this.students = [];
            data.forEach(student => {
                this.students.push({
                    id: student.id,
                    name: student.last_name + ', ' + student.first_name,
                    classId: student.class_id
                })
            });
        },

        setDateRange (dates) {
            this.dateRange = dates;
            if(dates.length == 1) this.dateRange = [
                dates[0],
                dates[0]
            ]            
            this.$emit('set-date-range', this.dateRange);            
        },

        show (item) {
            this.$emit('show-students');
            this.setAttendanceSelectedStudent(item);
            this.expandStudents = false;
            this.selectedStudent = item;
            this.$emit('set-student-id', item.id)
            setTimeout(() => {
                this.expandStudentAttendance = true;
            }, 600)
            this.getStudentAttendanceRecords();
        },

        async getStudentAttendanceRecords () {
            this.attendanceRecords = [];
            this.loadingAttendance = true;
            this.loadedAttendance = false;
            this.loadedPunctuality= false;
            this.overlay = true;
            try {
                const { data } = await this.getAttendanceRecords();
                const { term } = data;
                this.term = term; 
                this.mapAttendance(data);
                this.mapRegularityChartData(data);
                this.mapPunctuality(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
            this.loadedAttendance = true;
            this.loadedPunctuality = true;
            this.loadingAttendance = false;
        },

        mapAttendance ({ attendance_records }) {
            const records = [];
            try {
                attendance_records.forEach((record) => {
                    let attendanceRecord = {...record};
                    attendanceRecord.am = record.attendance_am;
                    attendanceRecord.pm = record.attendance_pm;
                    attendanceRecord.late = "--";
                    attendanceRecord.time_scanned_in = record.time_scanned_in ? 
                    format(new Date(record.date_scanned + 'T' +record.time_scanned_in), 'hh:mm:ss a') : 
                    record.time_scanned_in;

                    attendanceRecord.time_scanned_out = record.time_scanned_out ? 
                    format(new Date(record.date_scanned + 'T' +record.time_scanned_out), 'hh:mm:ss a') : 
                    record.time_scanned_out;

                    if(record.time_in && !record.time_out){
                        attendanceRecord.am = "mdi-check"
                        attendanceRecord.pm = "mdi-check"
                    } 
                    if(record.late){
                        attendanceRecord.late = "Y"
                    }
                    records.push(attendanceRecord)
                   
                    
                });
            } catch (error) {
                console.log(error);
            }
            this.attendanceRecords = records;
            this.loadedAttendance = true;
        },

        mapRegularityChartData ({ possible_attendance, attendance}) {
            // this.attendanceData.datasets.data = [regularity, possible_attendance-regularity]
            this.attendanceData = {
                labels: ['Present', 'Absent'],
                datasets: [{
                    label: 'Current Attendance',
                    data: [attendance,possible_attendance-attendance],
                    backgroundColor: [
                        'rgb(254, 189, 75)',
                        'rgb(220, 220, 220)',
                    ],
                    borderJoinStyle: 'round',
                    borderRadius: 0,
                    hoverOffset: 4,
                    borderWidth: 0,
                    
                }]
            }

            this.regularityPercentage = possible_attendance ? ((attendance / possible_attendance)*100).toFixed(0) + "%": "0%";
            this.regularity = `Present: ${attendance}`;
            this.sessionsAbsent = `Absent: ${possible_attendance-attendance}`;
        },

        mapPunctuality ({ lates, attendance}) {
            this.lateData = {
                labels: ['Early', 'Late'],
                datasets: [{
                    label: 'Current Attendance',
                    data: [attendance-lates, lates],
                    backgroundColor: [
                        'rgb(0, 208, 203)',
                        'rgb(220, 220, 220)',
                    ],
                    borderJoinStyle: 'round',
                    borderRadius: 0,
                    hoverOffset: 4,
                    borderWidth: 0,
                    
                }]
            }
            this.punctualityPercentage = attendance ? (((attendance - lates) / attendance)*100).toFixed(0) + "%" : "0%";
            this.punctuality = `Late: ${lates} `;
        },

        editAttendance (item) {
            console.log(item)
        },

        displayReport () {
            // let today = format(new Date(), 'yyyy-MM-dd');
            // if(this.dateRange.length === 0) this.dateRange.push(today);
            this.$emit('show-student-attendance-report', this.dateRange);
        },

        closeReportMenu () {
            this.overlayMenu = false;
            this.$emit('show-attendance-report-menu', false);
        },

        expandAttendanceDetails () {
            this.expand = true;
            this.tableHeight = '45vh'
        },

        showAttendanceCharts () {
            this.expand = false;
            this.tableHeight = this.defaultTableHeight
        },

        showAttendanceReport () {
            this.$emit('show-attendance-report-menu');
        }
    }
}
</script>