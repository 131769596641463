<template>
    <tr>
        <td style="text-align:center">            
            <v-select
                v-model="itemModel.subject_id"                
                :items="subjects"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin"
                :filled="!admin" 
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.title }}
                </template>
            </v-select>
        </td>
        <td>{{ itemModel.abbr}}</td>
        <td 
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.course_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="!admin"
                :filled="!admin" 
            ></v-text-field>
        </td>        
        <td 
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.exam_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="disableExamMark" 
                :filled="disableExamMark"
            ></v-text-field>
        </td>
       
        <td>
            <v-select
                v-model="itemModel.conduct"                
                :items="commentsConduct"
                item-value="id"
                outlined
                dense
                hide-details                
                :disabled="disableConduct" 
                :filled="disableConduct"
                @change="updateRecord"                
            >
                <template v-slot:selection="{item}">
                    <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
        </td>

        <td>
            <v-select
                v-model="itemModel.application"                
                :items="commentsApplication"
                item-value="id"
                outlined
                dense
                hide-details                
                :disabled="disableApplication" 
                :filled="disableApplication"
                @change="updateRecord"                
            >
                <template v-slot:selection="{item}">
                    <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
        </td>

        <td>
            <v-select
                v-model="itemModel.preparedness"                
                :items="commentsPreparedness"
                item-value="id"
                outlined
                dense
                hide-details                
                :disabled="disablePreparedness" 
                :filled="disablePreparedness"
                @change="updateRecord"                 
            >
                <template v-slot:selection="{item}">
                    <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
        </td>
        
       
        <td 
            style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis;"
        >            
            <v-select
                v-model="itemModel.coded_comment"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="disableCodedComments"  
                :filled="disableCodedComments"
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                                   
        </td>
        <td 
            style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis;"
        >
            <v-select
                v-model="itemModel.coded_comment_1"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="disableCodedComments"
                :filled="disableCodedComments"  
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                               
        </td>
        
        <td>
            <v-btn 
                icon
                @click="deleteRecord"
                :disabled="!admin" 
            >
                <v-icon
                    small
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>        
    </tr>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        item: Object,
        formLevel: Number,
        term: Number,
        paper3: Boolean,
        subjectWeights: Array,              
    },
    
    mounted: function () {        
        this.codedComments1 = [...this.codedComments];
        this.codedComments2 = [...this.codedComments];
        this.commentsApplication = [...this.comments3];
        this.commentsPreparedness = [...this.comments3];
        this.commentsConduct = [...this.comments3];
        this.setDisabledFields();
    },

    watch: {
        item: {
            handler(){
                console.log('record changed')
                this.setDisabledFields();
            },
            deep: true,
        }
    },

    data: () => ({
        subject: null,
        codedComments1: [],
        codedComments2: [],
        commentsConduct: [],
        commentsApplication: [],
        commentsPreparedness: [], 
        comments3: [
            { id: null, comment: null},
            { id: 1, comment: 'Excellent' },
            { id: 2, comment: 'Good' },
            { id: 3, comment: 'Satisfactory' },
            { id: 4, comment: 'More Effort Required' },
            { id: 5, comment: 'Cause for Concern' },
        ],
        disableConduct: false,
        disableApplication: false,
        disablePreparedness: false,
        disableCodedComments: false,
        disableExamMark: false,
    }),

    computed: {
        ...mapGetters({
            subjects: 'admin/getSubjects',
            codedComments: 'termReports/getCodedComments',
            admin: 'auth/getAdmin',
            lessonSelected: 'termReports/getLessonSelected', 
            selectedFormClass: 'termReports/getSelectedFormClass',  
            currentTerm: 'termReports/getCurrentTerm',         
        }),

        itemModel () {
            return {...this.item};
        },       
        
    },    

    methods: {
        ...mapActions({
            postTable2Record: 'admin/postUpdateTable2',
            getTable2Records: 'termReports/getTable2Records',
        }),

        ...mapMutations({
            setTable2Record: 'admin/setSelectedTable2Record',           
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            setSnackbar: 'admin/setSnackbar',
            setDialogDelete: 'admin/setDialogDelete',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
        }),

        async updateRecord () { 
            // this.overlay(true);
            // this.loading(true);
            // console.log(this.itemModel);
            this.setSaving(true);
            this.setSaveStatus("Updating Record");
            this.setSaved(false);
            this.setSaveError(false);

            let table2Record = Object.assign({}, this.itemModel);
            table2Record.subject_id_old = this.item.subject_id;
            table2Record.subject_id_new = this.itemModel.subject_id;
            console.log(table2Record);
            this.setTable2Record(table2Record);
            try {
                let response = await this.postTable2Record();
                this.$emit('update-average');
                this.setTable2Records([]);
                response = await this.getTable2Records();
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);

                this.setSaving(false);
                this.setSaveStatus("Record Updated");
                this.setSaved(true);
                this.setSaveError(false);

                this.setSnackbar({
                    text: `Record Updated`,
                    display: true,
                    color: 'primary',
                }); 

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });
                
                this.setSaving(false);
                this.setSaveStatus("Error Occured!");
                this.setSaved(false);
                this.setSaveError(true);
            }

            // this.overlay(false);
            // this.loading(false);
        },

        deleteRecord () {
            // console.log(`Delete ${this.itemModel.abbr}`);
            // console.log(this.item);
            this.setDialogDelete({
                display: true,
                text: `Delete ${this.itemModel.title} ?`,
                cancel: true,
                deleteSubject: true,
                deleteStudent: false,
                ok: false
            })            
            this.setTable2Record(this.item);
            
        },

        setDisabledFields(){
            // let formLevel = this.lessonSelected.formLevel;            
            let className = this.selectedFormClass;
            let formClass = className ? className.substring(0,2) : null;
            let formLevel = className ? className.substring(0,1) : null;
            
            // console.log(`Form Class: ${formClass}`);
            // console.log(`Term: ${this.currentTerm}`);
            // console.log(`Form Level: ${this.formLevel}`);
            // console.log(`Admin: ${this.admin}`);
            
            this.disableApplication = true;
            this.disableConduct = true;
            this.disablePreparedness = true;
            this.disableCodedComments = true;
            this.disableExamMark = true;

            if(this.currentTerm == 2 && (formLevel == 5 || formClass == '6U' || formClass == '6L') && this.admin){
                this.disableExamMark = false;
            }

            if(formLevel < 6 && this.admin){
                this.disableConduct = false;
                this.disableApplication = false;
                this.disablePreparedness = false;
                this.disableExamMark = false;
                return;                
            }

            if(this.admin){
                this.disableCodedComments = false;
            }          
         
        },
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input{
        width: 40px;
    }    

    ::v-deep .v-select__slot{
        height: 20px;
    }

    ::v-deep .v-input__icon{
        height: 5px;
        min-width: 8px;
        width: 8px;
    }

    ::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections{
        height: 20px;
        padding: 0px;
    }

    ::v-deep .v-input input{
        max-height: 20px;
    }

    ::v-deep .v-btn--icon.v-size--default{
        height: 20px;
        width: 20px;
    }

    
</style>