<template>
    <tr @click="recordSelected">
        <td>{{ markRecord.count }}</td>
        <td class="pt-1" style="font-size: 0.9rem">
            {{ markRecord.name }}
        </td>
        <td>
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                hide-details
                @blur="recordChanged"
                type="number"
                min=0
                max=100
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"
            ></v-text-field>
        </td>
        <td>
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
                type="number"
                min=0
                max=100
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"
            ></v-text-field>
        </td>
        <td>
           <v-select
                v-model="conduct"
                :items="commentsConduct"
                item-value="id"
                outlined
                dense
                hide-details
                return-object
                :filled="disableConduct"
                :disabled="disableConduct"
                @change="recordChanged"
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                >
                    {{ data.item.id}} {{ data.item.comment }}
                </template>

            </v-select>
        </td>
        <td>
           <v-select
                v-model="application"
                :items="commentsApplication"
                item-value="id"
                outlined
                dense
                hide-details
                return-object
                :filled="disableApplication"
                :disabled="disableApplication"
                @change="recordChanged"
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                >
                    {{ data.item.id}} {{ data.item.comment }}
                </template>

            </v-select>
        </td>
        <td>
           <v-select
                v-model="preparedness"
                :items="commentsPreparedness"
                item-value="id"
                outlined
                dense
                hide-details
                return-object
                :filled="disablePreparedness"
                :disabled="disablePreparedness"
                @change="recordChanged"
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                >
                    {{ data.item.id}} {{ data.item.comment }}
                </template>

            </v-select>
        </td>
        <td>
           <v-select
                v-model="codedComment"
                :items="comments1"
                item-value="id"
                outlined
                dense
                hide-details
                return-object
                :filled="disableCodedComment"
                :disabled="disableCodedComment"
                @change="recordChanged"
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                >
                    {{ data.item.id}} {{ data.item.comment }}
                </template>

            </v-select>
        </td>
        <td>
            <v-select
                v-model="codedComment1"
                :items="comments2"
                item-value="id"
                outlined
                dense
                hide-details
                return-object
                :filled="disableCodedComment1"
                :disabled="disableCodedComment1"
                @change="recordChanged"
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                >
                    {{ data.item.id}} {{ data.item.comment }}
                </template>

            </v-select>
        </td>
        <td>
            <v-tooltip
                bottom

            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        :color="themeColor"
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>
                <span>Saved</span>
            </v-tooltip>
            <v-tooltip
                top

            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>
                <span>Error</span>
            </v-tooltip>
            <v-progress-circular
                indeterminate
                :color="themeColor"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>


</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,
    },
    created () {
        this.initialize();
    },
    mounted() {

        //console.log(this.markRecord);
        //this.setComments();
    },
    watch: {
        codedComments: {
            deep: true,
            handler(value){
                if(value.length != 0){
                    this.setComments();
                }
            }
        }
    },
    data() {
        return {
            commentsConduct: [],
            commentsApplication: [],
            commentsPreparedness: [],
            comments1: [],
            comments2: [],
            markRecord: {},
            codedComment: { id: null, comment: null },
            codedComment1: { id: null, comment: null },
            conduct: { id: null, comment: null },
            application: { id: null, comment: null },
            preparedness: { id: null, comment: null },
            comments3: [
                { id: null, comment: null},
                { id: 1, comment: 'Excellent' },
                { id: 2, comment: 'Good' },
                { id: 3, comment: 'Satisfactory' },
                { id: 4, comment: 'More Effort Required' },
                { id: 5, comment: 'Cause for Concern' },
            ],
            selectColumns:[
                { text: 'Conduct', model: 'conduct' }
            ],
            saved: false,
            saving: false,
            error: false,
            disableConduct: false,
            disableApplication: false,
            disablePreparedness: false,
            disableCodedComment: false,
            disableCodedComment1: false,
            disableExamMark: false,
            termMarkOnly: false,
        }
    },
    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
        }),
        themeColor(){
            return this.primaryColor + ' ' + this.colorDarken;
        },
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',
        }),

        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),

        initialize(){
            this.markRecord = {...this.studentRecord};
            this.savedDataCheck();
            this.setDisabledFields();
            if(this.currentTerm == 2) this.termMarkOnly = true;
            //console.log(this.currentTerm);
            //console.log(this.lessonSelected);
        },

        async recordChanged(){
            //console.log(this.recordBlankCheck());
            if(!this.recordBlankCheck()){
                console.log('record changed...');
                this.saving = true;
                this.saved = false;
                this.error = false;
                this.setUpdatedRecord(
                {
                    "student_id" : this.markRecord.student_id,
                    "year" : this.markRecord.year,
                    "term" : this.markRecord.term,
                    "subject_id" : this.markRecord.subject_id,
                    "exam_mark" : this.markRecord.exam_mark,
                    "course_mark" : this.markRecord.course_mark,
                    "conduct" : this.conduct.id,
                    "application" : this.application.id,
                    "preparedness" : this.preparedness.id,
                    "coded_comment" : this.codedComment.id,
                    "coded_comment_1" : this.codedComment1.id,
                    "employee_id" : this.employeeId,
                }
            );
                let response = await this.save();
                //console.log(response);

                if(response && (response.status === 200 || response.status === 201)){
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                    //console.log(this.marksEntered);
                    //let entered = this.marksEntered;
                    //this.setMarkSheetEntered(++entered);
                }
                else{
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }
            }
        },
        recordBlankCheck(){
            let record = [];
            let blank = true;
            record.push(this.markRecord.course_mark);
            record.push(this.markRecord.exam_mark);
            record.push(this.markRecord.conduct);
            record.push(this.markRecord.application);
            record.push(this.markRecord.preparedness);
            record.push(this.markRecord.coded_comment);
            record.push(this.markRecord.coded_comment_1);

            record.forEach(element => {
                if(element) blank = false;
            })

            return blank;
        },
        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },
        recordSelected(){
            //console.log('marksheetrecord: ', this.markRecord);
            console.log("record selected");
            let records = this.table2Records;
            console.log(records);
            let entered = 0;
            records.forEach(record => {
                if(record.course_mark || record.exam_mark || record.conduct || record.application || record.preparedness || record.coded_comment || record.coded_comment_1){
                    entered++;
                }
            })
            console.log(entered);
            //console.log(records);
            this.setStudentSelected(this.markRecord.name);
        },
        setComments(){
            this.comments1.push({id: null, comment: null});
            this.comments2.push({id: null, comment: null});
            this.codedComments.forEach(record =>{
                this.comments1.push({ id: parseInt(record.id), comment: record.detail });
                this.comments2.push({ id: parseInt(record.id), comment: record.detail });
            });
            this.commentsConduct = [...this.comments3];
            this.commentsApplication = [...this.comments3];
            this.commentsPreparedness = [...this.comments3];
            if(this.studentRecord.conduct){
                this.conduct = { id: parseInt(this.studentRecord.conduct) }
                //console.log(this.conduct);
            }
            if(this.studentRecord.application){
                this.application = { id: parseInt(this.studentRecord.application) }
            }
            if(this.studentRecord.preparedness){
                this.preparedness = { id: parseInt(this.studentRecord.preparedness) }
            }
            if(this.studentRecord.coded_comment){
                this.codedComment = { id: parseInt(this.studentRecord.coded_comment) };
            }
            if(this.studentRecord.coded_comment_1){
                this.codedComment1 = { id: parseInt(this.studentRecord.coded_comment_1) };
            }

        },
        setDisabledFields(){
            //console.log(this.lessonSelected);
            let formLevel = this.lessonSelected.formLevel;
            // let className = this.lessonSelected.className;
            // let formClass = className ? className.substring(0,2) : null;
            if(formLevel < 6){
                this.disableConduct = false;
                this.disableApplication = false;
                this.disablePreparedness = false;
                this.disableCodedComment = true;
                this.disableCodedComment1 = true;
            }
            else{
                this.disableConduct = true;
                this.disableApplication = true;
                this.disablePreparedness = true;
                this.disableCodedComment = false;
                this.disableCodedComment1 = false;
            }
            if(this.currentTerm == 2 && (formLevel < 5)){
                this.disableExamMark = true;
            }
        },

    }
}
</script>

<style scoped>

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center;
    }
</style>