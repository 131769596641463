<template>
    <v-container class="pb-0">
        <v-data-table
            :items="table2Records"
            :loading="loading"
            :headers="headers"
            :height="height"            
            fixed-header           
            class="enter-marks-table"
            disable-pagination
            hide-default-footer                                       
        >
            <template v-slot:item="props">
                <Record
                    v-bind:studentRecord="props.item"
                />
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Record from './EnterMarksSheetRecord';
import { mapGetters } from 'vuex';
export default {
    components: {
        Record,
    },
    created () {
        //this.initialize();
    },
    data: () =>({
        loading: false,
        height: "45vh",
        pagination: false,
        footer: false,                
        headers: [],       
        
    }),
    watch: {
        selectedLesson () {
            console.log('lesson selected');
            this.initialize();
        }
    },    
    computed:{
        ...mapGetters({            
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            selectedLesson: 'termReports/getLessonSelected',
        }),
    },
    methods: { 
       initialize(){
            console.log(`Current Term: ${this.currentTerm}`);
            //console.log(this.selectedLesson.className.substring(0,2));
            // let className = this.selectedLesson.className;
            let formLevel  = this.selectedLesson.formLevel;
            // let formClass = className ? className.substring(0,2) : null;
            console.log(formLevel);
            if(this.currentTerm == 2 && formLevel < 5 ){                
                this.headers = [
                        {text: '#', value: 'count', width: '45', sortable: false},
                        {text: 'Name', value: 'name', width: '110', sortable: false,},
                        {text: 'Term', value: 'courseMark', align: 'center', width: '80', sortable: false,},            
                        {text: '---', value: '', align: 'center', width: '80', sortable: false,},            
                        {text: 'Conduct', value: 'conduct', align: 'center', width: '90', sortable: false,},            
                        {text: 'Application', value: 'application', align: 'center', width: '90', sortable: false,},            
                        {text: 'Preparedness', value: 'preparedness', align: 'center', width: '90', sortable: false,},            
                        {text: 'Coded Comment', value: 'codedComment1', align: 'center', width: '100', sortable: false,},            
                        {text: 'Coded Comment 1',  value: 'codedComment2', align: 'center', width: '100', sortable: false,},
                        {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
                    ];       
            }
            // else if(this.currentTerm == 2 && formClass == '6L'){
            //     this.headers = [
            //             {text: '#', value: 'count', width: '45', sortable: false},
            //             {text: 'Name', value: 'name', width: '110', sortable: false,},
            //             {text: 'Mark', value: 'courseMark', align: 'center', width: '80', sortable: false,},            
            //             {text: '---', value: '', align: 'center', width: '80', sortable: false,},            
            //             {text: 'Conduct', value: 'conduct', align: 'center', width: '90', sortable: false,},            
            //             {text: 'Application', value: 'application', align: 'center', width: '90', sortable: false,},            
            //             {text: 'Preparedness', value: 'preparedness', align: 'center', width: '90', sortable: false,},            
            //             {text: 'Coded Comment', value: 'codedComment1', align: 'center', width: '100', sortable: false,},            
            //             {text: 'Coded Comment 1',  value: 'codedComment2', align: 'center', width: '100', sortable: false,},
            //             {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
            //         ];       
            // }
            else{                    
                    this.headers = [
                        {text: '#', value: 'count', width: '45', sortable: false},
                        {text: 'Name', value: 'name', width: '110', sortable: false,},
                        {text: 'Term', value: 'courseMark', align: 'center', width: '80', sortable: false,},            
                        {text: 'Exam', value: 'examMark', align: 'center', width: '80', sortable: false,},            
                        {text: 'Conduct', value: 'conduct', align: 'center', width: '90', sortable: false,},            
                        {text: 'Application', value: 'application', align: 'center', width: '90', sortable: false,},            
                        {text: 'Preparedness', value: 'preparedness', align: 'center', width: '90', sortable: false,},            
                        {text: 'Coded Comment', value: 'codedComment1', align: 'center', width: '100', sortable: false,},            
                        {text: 'Coded Comment 1',  value: 'codedComment2', align: 'center', width: '100', sortable: false,},
                        {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
                    ]    
            }
       }
    }
}
</script>

<style scoped>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    .v-data-table > .v-data-table__wrapper > table{
        table-layout: fixed;
    }
    th span{
        display: flex;
        justify-content: center;
    }
    
</style>